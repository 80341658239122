import styled, { css } from "styled-components";
import { TopPlayersItem, TopPlayers } from "@Modules/Widgets/TopPlayers";

const Alliance = css`
	right: 3.5%;
`;

const Horde = css`
	left: 3.5%;
`;

export default styled(TopPlayers)`
	font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
	transform: perspective(calc(35vh)) rotateX(25deg);
	text-align: justify;
	position: absolute;
	font-weight: bold;
	overflow: hidden;
	font-size: 300%;
	height: 60%;
	width: 33%;
	display: flex;
	flex-direction: column;
	transform-origin: 50% 100%;
	padding: 7.4% 3.5% 12% 2%;
	gap: 2rem;
	top: -6%;
	${({ fraction }) => ({ Alliance, Horde })[fraction]};
	
	${TopPlayersItem} {
		display: flex;
		font-size: 5vh;
		justify-content: space-between;
		color: #FCDF2C;
	}
`;