import React from "react";
import LogoOverlay from "@Modules/Widgets/LogoOverlay";

import l2p from '../assets/logos/l2p.jpg';
import bubaku from '../assets/logos/bubaku.jpg';
import animerch from '../assets/logos/animerch.jpg';
import fadee from '../assets/logos/fadee.jpg';
import monster_print from '../assets/logos/monster_print.jpg';
import poggers from '../assets/logos/poggers.jpg';
import angry_beards from '../assets/logos/angry_beards.jpg';

const Overlay = () => <LogoOverlay logoList={[l2p, bubaku, animerch, fadee, monster_print, poggers, angry_beards]} />;

export default Overlay;