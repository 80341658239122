export const questsList = [
	{
		name: "Vanilla",
		start: "15:00",
		end: "16:00",
	},
	{
		name: "The Burning Crusade",
		start: "16:00",
		end: "18:00",
	},
	{
		name: "Wrath of the Lich King",
		start: "18:00",
		end: "19:00",
	},
	{
		name: "Cataclysm",
		start: "19:00",
		end: "20:00",
	},
	{
		name: "Looking for Group",
		start: "20:00",
		end: "21:00",
	},
	{
		name: "the Great Vault",
		start: "21:30",
		end: "23:59",
	},
];

export type QuestName = keyof typeof questsList;