import React, { useState } from "react";
import { useInterval } from "@Utils/hooks";
import styled from "styled-components";

type OverlayProps = {
	logoList: Array<string>;
};

const StyledOverlay = styled.div`
	z-index: 999;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100vw;
	height: 100vh;

	img {
		width: 100vw;
		height: 100vh;
	}
`;

const Overlay = ({ logoList }: OverlayProps) => {
	const [id, setId] = useState(1);
	const [isVisible, setIsVisible] = useState(false);

	const showOverlay = () => {
		setIsVisible(true);
		setTimeout(() => {
			setId(prev => prev + 1);
			setIsVisible(false);
		}, 6 * 1000); // 6 seconds
	};

	useInterval(() => {
		showOverlay();
	}, 5 * 60 * 1000); // 5 minutes

	if (!isVisible) {
		return null;
	}

	return (
		<StyledOverlay>
			<img src={logoList[id % logoList.length]} alt="logo"/>
		</StyledOverlay>
	);
};

export default Overlay;