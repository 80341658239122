import React, {useState} from "react";
import styled from 'styled-components';
import classNames from 'classnames';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

type LayoutProps = {
    className?: string;
    onRegistered: () => void;
}

const UsersRegistration = ({ className, onRegistered }: LayoutProps) => {
    const [badgeId, setBadgeId] = useState<string>();
    const [nickname, setNickname] = useState<string>();
    const [fractionCode, setFractionCode] = useState<'H'|'A'>();
    const isValid = !!nickname && !!badgeId && !!fractionCode;

    const register = () => {
        fetch('/api/users', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                badgeId,
                nickname,
                fractionCode
            }),
        }).then(() => {
            onRegistered();
            setBadgeId(undefined);
            setNickname(undefined);
            setFractionCode(undefined);
        })
    };

    return (
        <div className={className}>
            <h1 className="text-center">Registrace</h1>
            <div className="input-group mb-3">
                <span
                    className={classNames('input-group-text', { 'active': fractionCode === 'H' })}
                    id="hordeButton"
                    onClick={() => setFractionCode('H')}
                >
                    H
                </span>
                <span
                    className={classNames('input-group-text', { 'active': fractionCode === 'A' })}
                    id="allianceButton"
                    onClick={() => setFractionCode('A')}
                >
                    A
                </span>
                <input
                    type="number"
                    className="form-control"
                    placeholder="Badge ID"
                    value={badgeId ?? ''}
                    onChange={e => setBadgeId(e.target.value)}
                />
                <input
                    type="text"
                    className="form-control"
                    placeholder="Nickname"
                    value={nickname ?? ''}
                    onChange={e => setNickname(e.target.value)}
                />
                <button className="btn btn-success" disabled={!isValid} onClick={() => register()}>Uložit</button>
            </div>
        </div>
    )
};

export default styled(UsersRegistration)`
  #hordeButton, #allianceButton {
    cursor: pointer;
  }
  #hordeButton.active {
    background-color: red;
    color: white;
  }
  #allianceButton.active {
    background-color: blue;
    color: white;
  }
`;