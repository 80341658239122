import React from "react";
import styled from "styled-components";

type TopPlayersItemProps = {
    name: string;
    count: string|number;
    className?: string;
}

const TopPlayersItem = ({ className, name, count }: TopPlayersItemProps) => (
    <div className={className}>
        <div>{name}</div>
        <div>{count}</div>
    </div>
);

export default styled(TopPlayersItem)(() => ({}));