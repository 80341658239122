import React from "react";
import BoardProvider from "@Modules/Board/BoardProvider";
import { useBackground } from "@Modules/Board/starwars/useBackground";

import { TopPlayers, ProgressBar } from "./widgets";
import styled from "styled-components";
import swLogoSrc from "./assets/sw_logo.png";
import barLogoSrc from "./assets/logo.jpg";
import obiWanSrc from "./assets/obi-wan.png";
import vaderSrc from "./assets/vader.png";
import lightForceSrc from "./assets/lightForce.gif";
import darkForceSrc from "./assets/darkForce.gif";

type StyledWrapperProps = {
	children: React.ReactNode;
};

const SWLogo = styled.div`
	position: absolute;
	left: 30%;
	right: 32%;
	aspect-ratio: 1600/629;
	top: 13%;
	background-size: 100% 100%;
	background-image: url("${swLogoSrc}");
	padding: 5% 3.3% 5% 2%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	text-align: center;
`;

const BarLogo = styled.div`
	position: absolute;
	left: 43%;
	right: 43%;
	aspect-ratio: 1/1;
	top: 43%;
	background-size: 100% 100%;
	background-image: url("${barLogoSrc}");
	padding: 5% 3.3% 5% 2%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	text-align: center;
`;

const ObiWan = styled.div`
	position: absolute;
	transform: scaleX(-1);
	z-index: 9;
	left: 75%;
	right: 5%;
	aspect-ratio: 580/497;
	bottom: -0.5%;
	background-size: 100% 100%;
	background-image: url("${obiWanSrc}");
	padding: 5% 3.3% 5% 2%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	text-align: center;
`;
const DarthVader = styled.div`
	position: absolute;
	transform: scaleX(-1);
	z-index: 9;
	left: 4%;
	right: 75%;
	aspect-ratio: 644/622;
	bottom: -4%;
	background-size: 100% 100%;
	background-image: url("${vaderSrc}");
	padding: 5% 3.3% 5% 2%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	text-align: center;
`;


const LightForce = styled.div`
	position: absolute;
	left: 24%;
	right: 24.2%;
	aspect-ratio: 1920/500;
	bottom: 10%;
	background-size: 100% 100%;
	background-image: url("${lightForceSrc}");
	padding: 5% 3.3% 5% 2%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	text-align: center;
`;

const DarkForce = styled.div`
	position: absolute;
	left: 24%;
	right: 24.2%;
	aspect-ratio: 1920/500;
	bottom: 10%;
	background-size: 100% 100%;
	background-image: url("${darkForceSrc}");
	padding: 5% 3.3% 5% 2%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	text-align: center;
`;

const StyledWrapper = ({ children }: StyledWrapperProps) => {
	const starsRef = useBackground();

	if(!starsRef) {
		return null;
	}

	return (
		<div ref={ref => starsRef.current = ref}>
			{children}
		</div>
	);
}

const Layout = () => (
	<BoardProvider>
		<StyledWrapper>
			<TopPlayers sort="ASC" fraction="Alliance"/>
			<SWLogo/>
			<BarLogo />
			<TopPlayers sort="ASC" fraction="Horde"/>
			<ProgressBar />
		</StyledWrapper>
	</BoardProvider>
);

export default Layout;

