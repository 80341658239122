import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import SelectWithSearch from "@Components/SearchWithSelect";
import useInterval from "@Utils/hooks/useInterval";

type LayoutProps = {
	className?: string;
	users: Array<any>;
}

const QuestList = [
	"The Wilderness",
	"Arcane Sanctuary",
	"Template of Fortitude",
	"The Arena",
	"Elemental Circle",
];

const SpellList = [
	"Purge",
	"Life Tap",
	"Potion of Ultimate Power",
	"Potion of Instant Boost",
	"Potion of RNGsus",
];

const BuffControl = ({ className, users }: LayoutProps) => {
	const [availableQuests, setAvailableQuests] = useState<any>();
	const [availableSpells, setAvailableSpells] = useState<any>();
	const [customer, setCustomer] = useState<any>();
	const options = useMemo(
		() => users?.map((user: any) => ({
			value: `${user.FractionCode}${user.BadgeId}`,
			label: `${user.FractionCode}${user.BadgeId}`,
			user,
		})) ?? [],
		[users]
	);

	const fetchQuests = useCallback(() =>
			fetch(`/api/quests/available/${customer.user.FractionCode}`)
				.then(response => response.json())
				.then((quests) => setAvailableQuests(quests)),
		[customer, setAvailableQuests]);

	const fetchSpells = useCallback(() =>
			fetch(`/api/spells/available/${customer.user.FractionCode}/${customer.user.CustommerId}`)
				.then(response => response.json())
				.then((spells) => setAvailableSpells(spells)),
		[customer, setAvailableSpells]);

	const applyModifier = (value: number) => fetch('/api/modifier/personal/apply', {
		method: 'POST',
		body: JSON.stringify({
			customerId: customer.user.CustommerId,
			value
		}),
	});

	useInterval(() => {
		fetchSpells();
		fetchQuests();
	}, 5000);

	useEffect(() => {
		if (customer) {
			fetchQuests();
			fetchSpells();
		}
	}, [customer, fetchQuests, fetchSpells]);

	const activateQuest = (questName: string) => {
		fetch('/api/quests/apply', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				user: {
					id: customer.user.CustommerId,
					fractionCode: customer.user.FractionCode,
				},
				questName,
			}),
		}).then(() => fetchQuests());
	};

	const activateBuff = (buffName: string) => {
		fetch('/api/buff/apply', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				buffName,
			}),
		});
	};

	const activateSpell = (spellName: string) => {
		fetch('/api/spells/apply', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				user: {
					id: customer.user.CustommerId,
					fractionCode: customer.user.FractionCode,
				},
				spellName,
			}),
		}).then(() => fetchSpells());
	};

	return (
		<div className={className}>
			<h1 className="text-center">Buffy</h1>
			<SelectWithSearch options={options} onChange={setCustomer}/>
			<div className="mt-5">
				<div className="mt-4 d-flex justify-content-between">
					<div className="btn-group">
						<button
							className="btn btn-primary"
							onClick={() => activateBuff("Heroism")}
						>
							Heroism
						</button>
						<button
							className="btn btn-danger"
							onClick={() => activateBuff("Bloodlust")}
						>
							Bloodlust
						</button>
					</div>
					{customer && (
						<div className="btn-group">
							<button className="btn btn-danger" onClick={() => applyModifier(-30)}>-30</button>
							<button className="btn btn-danger" onClick={() => applyModifier(-10)}>-10</button>
							<button className="btn btn-danger" onClick={() => applyModifier(-5)}>-5</button>
							<button className="btn btn-danger" onClick={() => applyModifier(-1)}>-1</button>
							<button className="btn btn-success" onClick={() => applyModifier(1)}>+1</button>
							<button className="btn btn-success" onClick={() => applyModifier(5)}>+5</button>
							<button className="btn btn-success" onClick={() => applyModifier(10)}>+10</button>
							<button className="btn btn-success" onClick={() => applyModifier(30)}>+30</button>
						</div>
					)}
				</div>
			</div>
		</div>
	)
};

export default styled(BuffControl)`

`;