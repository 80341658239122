import React, {useEffect, useMemo, useState} from "react";
import styled from 'styled-components';
import { useFetch, useInterval } from "@Utils/hooks";
import SelectWithSearch from "@Components/SearchWithSelect";

type LayoutProps = {
    className?: string;
}

const BarItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const BarItem = styled.div`
  display: flex;
  flex: 1 0 calc(100% / 3);
  aspect-ratio: 1/1;
  background-color: gray;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 20px;
  text-align: center;
`;

const getColor = (fractionCode: string|null) => {
    if(fractionCode === "H") {
        return 'red';
    }
    if(fractionCode === "A") {
        return 'blue';
    }

    return 'gray';
}

const OpenBar = ({ className }: LayoutProps) => {
    const [customer, setCustomer] = useState<any>();
    const [sortiment, setSortiment] = useState<any>();
    const { data } = useFetch<any>('/api/sortiment');
    const { data: users, reFetch } = useFetch<any>("/api/users");
    const renderItems = Object.values(sortiment ?? [])
        ?.filter((item: any) => ['For the Horde', 'Bloodlust', 'For the Alliance', 'Divine Shield', 'Gin Tonic', 'Skinny Bitch', 'Cuba Libre', 'Limo', 'Zelená'].includes(item.MenuItemName))
        ?.sort((a: any, b: any) => a.FractionCode.localeCompare(b.FractionCode));
    const price = sortiment ? Object.values(sortiment).reduce((acc: any, item: any) => acc + ((item.value ? item.value : 0) * item.menuItemPrice), 0) : 0;

    const options = useMemo(
        () => users?.map((user: any) => ({
            value: `${user.FractionCode}${user.BadgeId}`,
            label: `${user.FractionCode}${user.BadgeId}`,
            user,
        })) ?? [],
        [users]
    );

    const emptyState = useMemo(() => data?.reduce((acc: any, item: any) => ({
        [item.MenuItemId]: {
            ...item,
            value: 0,
        },
        ...acc,
    }) ?? {}, {}), [data]);

    useInterval(() => {
        reFetch();
    }, 5000);

    useEffect(() => {
        setSortiment(emptyState);
    }, [emptyState]);

    const updateOrder = (id: number, value: number) => {
        setSortiment((prev: any) => ({
            ...prev,
            [id]: {
                ...prev[id],
                value,
            }
        }));
    };

    const sendOrder = () => {
        fetch('/api/order', {
            method: 'POST',
            body: JSON.stringify({
                customerId: customer.user.CustommerId,
                fractionCode: customer.user.FractionCode,
                price,
                sortiment: Object.values(sortiment).filter((item: any) => item.value !== 0)
            }),
        }).then(() => {
            setSortiment(emptyState);
            setCustomer(undefined);
        });
    }

    return (
        <div className={className}>
            <div className="container">
                <div className="row">
                    <div className="col-6">
                        <SelectWithSearch options={options} onChange={setCustomer} />
                    </div>
                    <div className="col-6 text-end">
                        <button className="btn btn-success" onClick={sendOrder} disabled={!customer}>Odeslat</button>
                    </div>
                    <div className="col-12 text-end d-flex justify-content-center align-items-center">
                        cena: {(price as any).toString()} ,–
                    </div>
                </div>
            </div>
            <BarItemWrapper>
                {renderItems.map((item: any) => (
                    <BarItem key={item.MenuItemId} style={{ backgroundColor: getColor(item.FractionCode) }}>
                        {item.MenuItemName}
                        <input
                            style={{ width: "calc(100% - 20px)" }}
                            value={item.value ? item.value : ''}
                            onChange={e => updateOrder(item.MenuItemId, parseInt(e.target.value, 10))}
                        />
                    </BarItem>
                ))}
            </BarItemWrapper>
        </div>
    )
};

export default styled(OpenBar)`
  width: 100vw;
  height: 100vh;
`;