import React, { useState } from "react";
import styled from 'styled-components';
import SelectWithSearch from "@Components/SearchWithSelect";
import BarContextProvider, { useBar, User } from "./BarProvider";

type LayoutProps = {
	className?: string;
}

const BarItemWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	padding: 20px;
`;

const BarItem = styled.div`
	display: flex;
	flex: 1 0 calc(100% / 8);
	max-width: calc((100% / 7) - 5px);
	aspect-ratio: 1/1;
	background-color: gray;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	color: white;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 20px;
	text-align: center;
`;

const getColor = (fractionCode: string | null) => {
	if (fractionCode === "H") {
		return 'red';
	}
	if (fractionCode === "A") {
		return 'blue';
	}

	return 'gray';
}

const Bar = ({ className }: LayoutProps) => {
	const [selectedGroup, setSelectedGroup] = useState<string|null>(null);
	const { groups, order, users, updateOrder, price, resetOrder } = useBar();
	const [customer, setCustomer] = useState<User>();

	const sendOrder = () => {
		fetch('/api/order', {
			method: 'POST',
			body: JSON.stringify({
				customerId: customer?.CustommerId,
				fractionCode: customer?.FractionCode,
				price,
				sortiment: Object.values(order!).filter((item) => item.value !== 0)
			}),
		}).then(() => {
			resetOrder();
			setCustomer(undefined);
		});
	}

	return (
		<div className={className}>
			<div className="container mt-4">
				<div className="row">
					<div className="col-md-6">
						<SelectWithSearch options={users} onChange={(user) => setCustomer(user as User)}/>
					</div>
					<div className="col-md-3 text-end d-flex justify-content-center align-items-center">
						cena: {(price as any).toString()} ,–
					</div>
					<div className="col-md-3 text-end">
						<button className="btn btn-success" onClick={sendOrder} disabled={!customer}>Odeslat</button>
					</div>
				</div>
			</div>

			{!!groups && !!order && selectedGroup === null &&
				<>
					<BarItemWrapper>
						{Object.keys(groups).filter(item => item !== "default").map((item) => (
							<BarItem key={item} onClick={() => setSelectedGroup(item)}>{item}</BarItem>
						))}
					</BarItemWrapper>
					<BarItemWrapper>
						{groups["default"]?.map((item) => (
							<BarItem key={item.MenuItemId} style={{ backgroundColor: getColor(item.FractionCode) }}>
								{item.MenuItemName}
								<input
									style={{ width: "calc(100% - 20px)" }}
									value={Number.isNaN(order[parseInt(item.MenuItemId)].value) ? 0 : order[parseInt(item.MenuItemId)].value}
									onChange={e => {
										updateOrder(parseInt(item.MenuItemId), parseInt(e.target.value, 10));
									}}
								/>
							</BarItem>
						))}
					</BarItemWrapper>
				</>

			}
			{!!groups && !!order && selectedGroup !== null && (
				<BarItemWrapper>
					<BarItem style={{ backgroundColor: "green" }} onClick={() => setSelectedGroup(null)}>
						Zpět
					</BarItem>
					{groups[selectedGroup].map((item) => (
						<BarItem key={item.MenuItemId} style={{ backgroundColor: getColor(item.FractionCode) }}>
							{item.MenuItemName}
							<input
								style={{ width: "calc(100% - 20px)" }}
								value={Number.isNaN(order[parseInt(item.MenuItemId)].value) ? 0 : order[parseInt(item.MenuItemId)].value}
								onChange={e => {
									updateOrder(parseInt(item.MenuItemId), parseInt(e.target.value, 10));
								}}
							/>
						</BarItem>
					))}
				</BarItemWrapper>
			)}
		</div>
	)
};

const WithWrapper = () => (
	<BarContextProvider>
		<Bar/>
	</BarContextProvider>
);

export default styled(WithWrapper)`
	width: 100vw;
	height: 100vh;
`;