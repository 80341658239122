import React from "react";
import styled from 'styled-components';
import BoardProvider from "@Modules/Board/BoardProvider";

import { TopPlayers, Buff, ProgressBar, Quest, LogoOverlay } from "./widgets";

import src from './assets/logo.png';
import board from "./assets/board.png";
import "./Board.scss";
import { questsList } from "./quests";

const Logo = styled.div`
	position: absolute;
	left: 32%;
	right: 32%;
	aspect-ratio: 534/263;
	top: 14%;
	background-size: 100% 100%;
	background-image: url("${src}");
	padding: 5% 3.3% 5% 2%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	text-align: center;
`;

const StyledWrapper = styled.div`
	font-family: DefaultFont, sans-serif;
	position: relative;
	width: 100vw;
	height: 100vh;
	background-image: url("${board}");
	background-size: 100% 100%;
`;

const Layout = () => (
	<BoardProvider questList={questsList}>
		<StyledWrapper>
			<LogoOverlay/>
			<TopPlayers fraction="Alliance"/>
			<TopPlayers fraction="Horde"/>
			<Logo/>
			<Quest/>
			<Buff fraction="Alliance"/>
			<ProgressBar/>
			<Buff fraction="Horde"/>
		</StyledWrapper>
	</BoardProvider>
);

export default Layout;

