import styled, { css } from "styled-components";
import { TopPlayersItem, TopPlayers } from "@Modules/Widgets/TopPlayers";

import allianceBg from "../assets/alliance.png";
import hordeBg from "../assets/horde.png";

const Alliance = css`
	padding: 7.4% 3.5% 12% 2%;
	background-image: url("${allianceBg}");
	aspect-ratio: 301/527;
	left: 8.5%;
	top: 8%;
`;

const Horde = css`
	padding: 16% 4.5% 2.5% 2%;
	background-image: url("${hordeBg}");
	aspect-ratio: 964/1707;
	right: 7%;
	top: 1.5%;
`;

export default styled(TopPlayers)`
	position: absolute;
	width: 23%;
	background-size: 100% 100%;
	display: flex;
	font-size: 2.5vw;
	line-height: 2.5vw;
	flex-direction: column;
	text-shadow: #211c3b 1px 0 4px;
	gap: 1rem;
	${({ fraction }) => ({ Alliance, Horde })[fraction]};

	${TopPlayersItem} {
		display: flex;
		justify-content: space-between;
		color: #d2d2d2;
	}
`;