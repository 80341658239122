import React from "react";
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import {useFetch} from "../../utils/hooks/useFetch";
import UsersRegistration from "./components/UsersRegistration";
import BuffControl from "./components/BuffControl";

type LayoutProps = {
    className?: string;
}

const Users = ({ className }: LayoutProps) => {
    const { data: users, reFetch } = useFetch<any>("/api/users");

    return (
        <div className={className}>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <UsersRegistration onRegistered={() => reFetch()} />
                    </div>
                    <div className="col-md-6">
                        <BuffControl users={users} />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default styled(Users)`
    width: 100vw;
    height: 100vh;
    padding: 20px;
`;