import React, { createContext, useContext, useMemo, useState } from 'react';
import { useFetch, useInterval } from "@Utils/hooks";

export const FractionCodeList = {
	Alliance: 'A',
	Horde: 'H',
} as const;

type Quest = Readonly<{
	name: string;
	start: string;
	end: string;
}>;

type TopUser = {
	FractionCode: typeof FractionCodeList[keyof typeof FractionCodeList];
	Nickname: string;
	PointsTotal: string;
}

type TotalSum = {
	SumPointsAlliance: string;
	SumPointsHorde: string;
}

type Buff = {
	BuffCoefficient: string;
	BuffName: string;
	BuffPointsDelta: string;
	BuffsActivityId: string;
	FractionCode: typeof FractionCodeList[keyof typeof FractionCodeList];
	IsPurgeable: string;
}

type ActiveBuff = {
	[K in typeof FractionCodeList[keyof typeof FractionCodeList]]: Array<Buff>;
}

type BoardContextProps = {
	activeQuest: Quest | null;
	topUsers: Array<TopUser> | null;
	totalCount: TotalSum | null;
	buffs: ActiveBuff | null;
};

type BoardContextProviderProps = {
	children: React.ReactNode;
	questList?: Array<Quest>;
};

const BoardContext = createContext<BoardContextProps>({} as BoardContextProps);

const isQuestActive = (quest: Quest) => {
	const now = (new Date()).toLocaleTimeString(
		'cs-CZ',
		{ hour12: false, hour: '2-digit', minute: '2-digit' },
	);

	return now >= quest.start && now < quest.end;
}

const BoardContextProvider = ({ children, questList }: BoardContextProviderProps) => {
	const [quest, setQuest] = useState<Quest | null>(null);
	const { data: topUsers, reFetch: reFetchTop } = useFetch<Array<TopUser>>('api/users/top');
	const { data: totalCount, reFetch: reFEtchTotal } = useFetch<TotalSum>('api/users/total');
	const { data: buffs, reFetch: reFetchBuffs } = useFetch<ActiveBuff>('api/buffs/active');

	useInterval(() => {
		if(questList) {
			const activeQuest = Object.values<Quest>(questList).find(isQuestActive);

			if (quest !== activeQuest) {
				setQuest(activeQuest ?? null);
			}
		}

		reFetchTop();
		reFEtchTotal();
		reFetchBuffs();
	}, 2000);

	const value = useMemo(() => ({
		activeQuest: quest,
		topUsers,
		totalCount,
		buffs,
	}), [topUsers, totalCount, buffs, quest, setQuest, questList]);

	return <BoardContext.Provider value={value}>{children}</BoardContext.Provider>;
};

export const useBoard = () => useContext(BoardContext as React.Context<BoardContextProps>);

export default BoardContextProvider;
