import React from 'react';
import ReactDOM from 'react-dom/client';

import BrandingThemeProvider from "./providers/BrandingThemeProvider";
import Router from './Router';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <BrandingThemeProvider>
        <Router />
    </BrandingThemeProvider>
);
