import React from 'react';
import Select, { type SingleValue } from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';

type Option = {
    value: string;
    label: string;
};

type SelectWithSearchProps = {
    options: Array<Option>;
    onChange: (newOption: SingleValue<Option>) => void;
}

const SelectWithSearch = ({ options, onChange }: SelectWithSearchProps) => (
    <Select options={options} isSearchable onChange={onChange} />
);

export default SelectWithSearch;
