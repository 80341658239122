import { useEffect, useState } from "react";

export const useFetch = <Data>(url: string, method = 'GET', body = null) => {
    const [refetch, setRefetch] = useState(0);
    const [data, setData] = useState<Data|null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const reqInit = {
        method,
        ...(body ? { body: JSON.stringify(body) } : {}),
    };

    useEffect(() => {
        setIsLoading(true);
        setHasError(false);

        fetch(url, reqInit)
            .then((res) => res.json())
            .then((body: Data) => {
                setData(body);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                setHasError(true);
            });
    }, [url, body, method, refetch]);

    return {
        data,
        isLoading,
        hasError,
        reFetch: () => setRefetch(Math.random() * 100),
    };
};