import React from "react";
import styled from "styled-components";
import { FractionCodeList, useBoard } from "@Modules/Board/BoardProvider";

import TopPlayersItem from "./TopPlayersItem";

type TopPlayersProps = {
    className?: string;
    fraction: keyof typeof FractionCodeList;
    children?: React.ReactNode;
    sort?: 'ASC' | 'DESC';
};

const TopPlayers = ({ className, fraction, sort = 'DESC', children }: TopPlayersProps) => {
    const { topUsers, totalCount } = useBoard();
    const total = totalCount?.[`SumPoints${fraction}`] ? parseInt(totalCount?.[`SumPoints${fraction}`]) : 0;

    const topPlayers = topUsers
        ?.filter(({ FractionCode }) => FractionCode === FractionCodeList[fraction])
        ?.map((item) => ({ ...item, PointsTotal: item?.PointsTotal ? parseInt(item.PointsTotal) : 0 }))
        ?.sort((a, b) => {
            if (a.PointsTotal && b.PointsTotal) {
                return sort === 'ASC' ? a.PointsTotal - b.PointsTotal : b.PointsTotal - a.PointsTotal;
            } else if (a.PointsTotal) {
                return sort === 'ASC' ? -1 : 1;
            } else {
                return sort === 'ASC' ? 1 : -1;
            }
        });

    return (
        <div className={className}>
            <div className="list">
                {topPlayers?.map((item, index) => (
                    <TopPlayersItem key={index} name={item.Nickname} count={item.PointsTotal}/>
                ))}
            </div>
            <TopPlayersItem name="Total" count={total}/>
            {children}
        </div>
    );
}

export default styled(TopPlayers)(() => ({}));