import React from 'react';
import styled from "styled-components";

import obiWanSrc from "@Modules/Board/starwars/assets/obi-wan.png";
import vaderSrc from "@Modules/Board/starwars/assets/vader.png";
import lightForceSrc from "@Modules/Board/starwars/assets/lightForce.gif";
import darkForceSrc from "@Modules/Board/starwars/assets/darkForce.gif";
import { useBoard } from "@Modules/Board/BoardProvider";

const ObiWan = styled.div`
	position: absolute;
	transform: scaleX(-1);
	z-index: 9;
	left: 75%;
	right: 5%;
	aspect-ratio: 580/497;
	bottom: -0.5%;
	background-size: 100% 100%;
	background-image: url("${obiWanSrc}");
	padding: 5% 3.3% 5% 2%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	text-align: center;
`;

const DarthVader = styled.div`
	position: absolute;
	transform: scaleX(-1);
	z-index: 9;
	left: 4%;
	right: 75%;
	aspect-ratio: 644/622;
	bottom: -4%;
	background-size: 100% 100%;
	background-image: url("${vaderSrc}");
	padding: 5% 3.3% 5% 2%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	text-align: center;
`;

const ForceItem = styled.div`
	width: 51.8vw;
	height: 100%;
`;

const ForceItemWrapper = styled.div<{ percentage: number, side: 'left'|'right' }>`
	position: absolute;
	overflow: hidden;
	left: ${props => props.side === 'left' ? 0 : 'unset'};
	right: ${props => props.side === 'right' ? 0 : 'unset'};
	width: calc(51.8vw - ${props => props.percentage}%);
	transition: width 2s;
	height: 100%;
`;

const Divider = styled.div<{ left: number }>`
	position: absolute;
	z-index: 9;
	top: 15%;
	left: ${props => props.left}%;
	transition: left 2s;
	box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 1);
	content: "";
	height: 70%;
	width: 5px;
	background-color: white;
	border-radius: 5px;
	opacity: .9;
`;

const Force = styled.div`
	position: absolute;
	left: 24%;
	right: 24.2%;
	bottom: 10%;
	aspect-ratio: 1920/500;
`;

const LightForce = styled.div`
	position: absolute;
	right: 0;
	width: 51.8vw;
	height: 100%;
	background-size: 100% 100%;
	background-image: url("${lightForceSrc}");
`;

const DarkForce = styled.div`
	position: absolute;
	left: 0;
	width: 51.8vw;
	height: 100%;
	background-size: 100% 100%;
	background-image: url("${darkForceSrc}");
`;

const ProgressBar = () => {
	const { totalCount } = useBoard();
	const blue = totalCount?.SumPointsAlliance ? parseInt(totalCount?.SumPointsAlliance) : 0;
	const red = totalCount?.SumPointsHorde ? parseInt(totalCount?.SumPointsHorde) : 0;
	const total = blue + red;

	return (
        <>
	        <ObiWan />
	        <Force>
		        <ForceItemWrapper percentage={(blue / total) * 100} side="left">
			        <ForceItem>
				        <DarkForce />
			        </ForceItem>
		        </ForceItemWrapper>
		        <Divider left={(red / total) * 100} />
		        <ForceItemWrapper percentage={(red / total) * 100} side="right">
			        <ForceItem>
				        <LightForce />
			        </ForceItem>
		        </ForceItemWrapper>
	        </Force>
	        <DarthVader />
        </>
    );
}

export default ProgressBar;
