import React from "react";
import { ThemeProvider } from 'styled-components';

interface IColors {
    darken: string;
    lighten: string;
}

interface IFractionColors extends IColors {
    value: string;
}

interface IFraction {
    colors: {
        primary: IFractionColors
    }
}

export interface IThemeContext {
    fractions: {
        horde: IFraction,
        alliance: IFraction,
    },
    colors: {
        primary: IColors,
        secondary: IColors,
        tertiary: IColors,
    }
}

export const theme: IThemeContext = {
    fractions: {
        horde: {
            colors: {
                primary: {
                    value: "#0069ff",//"#85281b",
                    darken: "#3a100d",//"#46150e",
                    lighten: "#731e17",//"#f85237",
                },
            }
        },
        alliance: {
            colors: {
                primary: {
                    value: "#00ff93",//"#173f80",
                    darken: "#111f39",//"#0e254b",
                    lighten: "#214077",//"#4fb5f5",
                }
            }
        },
    },
    colors: {
        primary: {
            lighten: "#74BC00",
            darken: "#346305",
        },
        secondary: {
            lighten: "#B7BA00",
            darken: "#536400",
        },
        tertiary: {
            lighten: "#EA6100",
            darken: "#6D3C00",
        }
    }
};

const BrandingThemeProvider = ({ children }: { children: any }) => (
    <ThemeProvider theme={theme}>
        {children}
    </ThemeProvider>
);

export default BrandingThemeProvider;
