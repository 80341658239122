import React from "react";
import styled from "styled-components";
import paper from "../assets/paper.png";
import { useBoard } from "@Modules/Board/BoardProvider";

type QuestProps = {
    className?: string;
    children?: React.ReactNode;
};

const StyledQuestItem = styled.div`
  text-shadow: #211c3b 1px 0 4px;
  color: #d2d2d2;
`;

const StyledQuestWrapper = styled.div<QuestProps>`
  position: absolute;
  left: 36%;
  right: 35%;
  aspect-ratio: 373/222;
  top: 50%;
  background-size: 100% 100%;
  background-image: url("${paper}");
  padding: 5% 3.3% 5% 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.9vw;
  line-height: 55px;
  text-align: center;
`;

const Quest = () => {
    const { activeQuest } = useBoard();

    if(!activeQuest) {
        return null;
    }

    return (
        <StyledQuestWrapper>
            <StyledQuestItem>{activeQuest.name}</StyledQuestItem>
        </StyledQuestWrapper>
    );
}

export default Quest;