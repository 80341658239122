import React from "react";
import styled, {css} from "styled-components";
import buff from "../assets/buff.png";
import bloodlust from '../assets/icons/bloodlust.jpeg';
import heroism from '../assets/icons/heroism.jpeg';
import mark_of_the_wild from '../assets/icons/mark_of_the_wild.jpeg';
import arcane_intellect from '../assets/icons/arcane_intellect.jpeg';
import power_word_fortitude from '../assets/icons/power_word_fortitude.jpeg';
import battle_shout from '../assets/icons/battle_shout.jpeg';
import life_tap from '../assets/icons/life_tap.jpeg';
import { FractionCodeList, useBoard } from "@Modules/Board/BoardProvider";

type BuffProps = {
    className?: string;
    fraction: keyof typeof FractionCodeList;
}

const BuffList = {
    'Bloodlust': bloodlust,
    'Heroism': heroism,
    'Mark of the Wild': mark_of_the_wild,
    'Arcane Intellect': arcane_intellect,
    'Power Word: Fortitude': power_word_fortitude,
    'Battle Shout': battle_shout,
    'Life Tap': life_tap,
} as const;

type BuffName = keyof typeof BuffList;

const Item = styled.div`
  padding: 7.5% 11.5% 9.5% 10.5%;
  background-size: 100% 100%;
  background-image: url("${buff}");
  aspect-ratio: 200/168;
  height: 100%;

  img {
    width: 100%;
    aspect-ratio: 20/19;
    border-radius: 3px;
  }
`;

const Buff = ({ fraction, className }: BuffProps) => {
    const { buffs } = useBoard();
    const active = buffs?.[FractionCodeList[fraction]] ?? [];

    return (
        <div className={className}>
            {active.filter((item) => item.BuffName !== "Balance Buff").slice(0, 3).map(item => (
                <Item>
                    <img src={BuffList[item.BuffName as BuffName]} alt={item.BuffName} />
                </Item>
            ))}
        </div>
    );
}

const Alliance = css`
  left: 2.75%;
  flex-direction: row-reverse;
`;

const Horde = css`
  right: 2.5%;
`;

export default styled(Buff)`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 9.6%;
  top: 81.5%;
  width: 17.9%;
  ${({fraction}) => ({Alliance, Horde})[fraction]};
`;
