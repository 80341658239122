import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFetch, useInterval } from "@Utils/hooks";

type Sortiment = {
	FractionCode: "H" | "A" | "Z";
	GroupId: string;
	MenuItemId: string;
	MenuItemName: string;
	MenuItemType: string;
	Visibility: "1" | "0";
	menuItemPoints: string;
	menuItemPrice: string;
}

export type UserApi = {
	BadgeId: string;
	CustommerId: string;
	FractionCode: "H" | "A";
	Nickname: string;
	RegistrationTime: string;
}

export type User = UserApi & { value: string; label: string; };

type Order = Sortiment & { value: number };

type BarContextProps = {
	price: number;
	users: Array<User>;
	order: Record<number, Order> | null;
	groups: Record<string, Array<Sortiment>> | null;
	updateOrder: (id: number, value: number) => void;
	resetOrder: () => void;
}

type BarContextProviderProps = {
	children: React.ReactNode;
};

const BoardContext = createContext<BarContextProps>({} as BarContextProps);

const BarContextProvider = ({ children }: BarContextProviderProps) => {
	const { data: users, reFetch } = useFetch<Array<UserApi>>("/api/users");
	const { data: sortiment } = useFetch<Array<Sortiment>>('/api/sortiment');
	const [order, setOrder] = useState<Record<number, Order> | null>(null);
	const emptyState = useMemo(() => sortiment?.reduce((acc, item) => ({
		...acc,
		[item.MenuItemId]: { ...item, value: 0 }
	}), {} as Record<number, Order>) ?? null, [sortiment]);

	const updateOrder = useCallback((id: number, value: number) => {
		setOrder((prev) => ({ ...prev, [id]: { ...prev![id], value } }));
	}, [setOrder]);

	const value = useMemo(() => ({
		updateOrder,
		order,
		price: sortiment ? Object.values(order ?? {}).reduce((acc, item) => acc + ((item.value ? item.value : 0) * parseInt(item.menuItemPrice)), 0) : 0,
		groups: sortiment?.reduce((acc, item) => ({
			...acc,
			[item.GroupId]: [
				...(acc[item.GroupId] ?? []),
				item,
			]
		}), {} as Record<string, Array<Sortiment>>) ?? null,
		users: users?.map((user) => ({
			value: `${user.FractionCode}${user.BadgeId}`,
			label: `${user.FractionCode}${user.BadgeId}`,
			...user,
		})) ?? [],
		resetOrder: () => setOrder(emptyState),
	}), [order, sortiment, updateOrder, users]);

	useInterval(() => {
		reFetch();
	}, 5000);

	useEffect(() => {
		setOrder(emptyState);
	}, [emptyState]);

	return <BoardContext.Provider value={value}>{children}</BoardContext.Provider>;
};

export const useBar = () => useContext(BoardContext);

export default BarContextProvider;
