import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { WowBoard } from "@Modules/Board";
import { Bar, OpenBar } from "@Pages/Bar";
import Admin from "@Pages/Admin";

const Router = () => (
	<BrowserRouter>
		<Routes>
			<Route path="/" element={<WowBoard/>}/>
			<Route path="/bar/inn" element={<Bar/>}/>
			<Route path="/bar/open" element={<OpenBar/>}/>
			<Route path="/admin" element={<Admin/>}/>
		</Routes>
	</BrowserRouter>
);

export default Router;
